<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">财务模块</el-breadcrumb-item>
        <el-breadcrumb-item>定价及利润计算器</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <el-row :gutter="80" justify="center">
      <el-col :span="16" style="text-align: center; margin-bottom: 15px; font-weight: 600; font-size: larger;">
        平台定价以及利润计算器
      </el-col>
      <el-col :span="16"
        style="text-align: center; padding-bottom: 15px; margin-bottom: 15px; font-weight: 400; font-size: small; border-bottom: 1px solid #ccc;">
        (输入供货价、售价等系统自动计算对应的利润率，方便运营同事日常定价以及活动报送)
      </el-col>
    </el-row>
    <el-row :gutter="80" justify="center" style="border-bottom: 1px solid #ccc;margin-bottom: 15px;">
      <el-col :span="16">
        <el-form-item label="业务类型">
          <el-select v-model="form.index" placeholder="请选择业务类型" style="width: 480px" @change="selectChange()">
            <el-option v-for="(item, index) in types" :key="index" :label="item.label" :value="item.index">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right;color: var(--el-text-color-secondary);font-size: 13px;">
                {{ item.name }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <el-form-item label="描述功能">
          {{ form.desc }}
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="80" justify="center">
      <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
        <el-form :model="form" label-width="auto" :rules="rules">
          <el-form-item label="供货价" prop="productPrice">
            <el-input v-model="form.productPrice" @input="inputChange()" placeholder="供应商采购成本费用"
              style="max-width: 280px;" />
          </el-form-item>
          <el-form-item label="直接销售费用" prop="otherPrice">
            <el-input v-model="form.otherCertPrice" @input="otherChange()" placeholder="证书费"
              style="max-width: 80px; margin: 2px 2px;" />
            <el-input v-model="form.otherBrandPrice" @input="otherChange()" placeholder="入网费"
              style="max-width: 80px; margin: 2px 2px;" />
            <el-input v-model="form.otherPostPrice" @input="otherChange()" placeholder="快递费"
              style="max-width: 80px; margin: 2px 2px;" />
            <el-input v-model="form.otherFittPrice" @input="otherChange()" placeholder="配件费"
              style="max-width: 80px; margin: 2px 2px;" />
            <el-input v-model="form.otherGiftPrice" @input="otherChange()" placeholder="赠品费"
              style="max-width: 80px; margin: 2px 2px;" />
            <el-input v-model="form.otherExtraPrice" @input="otherChange()" placeholder="其他"
              style="max-width: 80px; margin: 2px 2px;" />
            <el-input v-model="form.otherPrice" disabled placeholder=""
              style="max-width: 150px;margin: 2px 2px;"><template #suffix>合计￥</template></el-input>
          </el-form-item>
          <el-form-item label="预备产品售价" prop="sellPrice">
            <el-input v-model="form.sellPrice" @input="inputChange()" placeholder="预备制定的售价" style="max-width: 180px;" />
          </el-form-item>
          <el-form-item label="平台扣点(%)" prop="chargeRatio">
            <el-input v-model="form.chargeRatio" @input="inputChange()" placeholder="百分比" style="max-width: 100px;">
              <template #suffix>
                %
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="开票扣点(%)" prop="taxRatio" v-show="form.index == 3 || form.index == 4">
            <el-input v-model="form.taxRatio" @input="inputChange()" placeholder="百分比" style="max-width: 100px;">
              <template #suffix>
                %
              </template>
            </el-input>
          </el-form-item>

          <el-form-item style="border-top: 1px solid #ccc;padding-top: 10px; margin-top: 25px;">
            <el-row style="width:100%">
              <el-form-item label-width="80px" label="增值税">
                ￥{{ $util.fmtMoney(form.addTaxPrice) || '0.00' }} 元
              </el-form-item>
              <el-form-item label="消费税">
                ￥{{ $util.fmtMoney(form.saleTaxPrice) || '0.00' }} 元
              </el-form-item>
              <el-form-item label-width="140px" label="城建税及附加">
                ￥{{ $util.fmtMoney(form.cityTaxPrice) || '0.00' }} 元
              </el-form-item>
            </el-row>
            <el-row style="width:100%">
              <el-form-item label-width="80px" label="利润额">
                ￥{{ $util.fmtMoney(form.profitPrice) || '0.00' }} 元
              </el-form-item>
              <el-form-item label-width="140px" label="业务利润率">
                {{ form.profitRatio }} %
              </el-form-item>
            </el-row>
          </el-form-item>

          <el-form-item style="border-top: 1px solid #ccc; padding-top: 10px;">
            <el-row style="width:100%">
              <el-form-item label-width="150px" label="若固定订单利润率">
                <el-input v-model="form.fix1ProfitRatio" @input="inverseChange()" placeholder="百分比"
                  style="max-width: 100px;"><template #suffix>%</template></el-input>
              </el-form-item>
              <el-form-item label-width="150px" label="则建议产品金额为">
                <el-input v-model="form.fix1SellPrice" @input="inverseChange()" placeholder="" disabled
                  style="max-width: 150px;"><template #suffix>￥</template></el-input>
              </el-form-item>
            </el-row>
            <el-row style="width:100%;margin-top: 5px;">
              <el-form-item label-width="150px" label="若固定订单金额">
                <el-input v-model="form.fix2SellPrice" @input="inverseChange()" placeholder="百分比"
                  style="max-width: 100px;"><template #suffix>￥</template></el-input>
              </el-form-item>
              <el-form-item label-width="150px" label="则订单利润率为">
                <el-input v-model="form.fix2ProfitRatio" @input="inverseChange()" placeholder="" disabled
                  style="max-width: 150px;"><template #suffix>%</template></el-input>
              </el-form-item>
            </el-row>
          </el-form-item>

          <el-form-item label=" " style="margin-top: 20px; padding-top: 10px; border-top: 1px solid #ccc;">
            <el-button @click="$router.back()">返回</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
        <el-form :model="form" label-width="auto">
          <el-row style="margin:10px 0">
            供货价：￥{{ form.productPrice || '0.00' }}，
            直接销售费用：￥{{ form.otherPrice || '0.00' }}，
            平台扣点：{{ form.chargeRatio || '0.00' }}
            <span v-show="(form.taxRatio || 0) > 0">，开票扣点：{{ form.taxRatio || '0.00' }}</span>
          </el-row>
          <el-form-item label="建议价格">
            <el-row v-for="(item, index) in ladders">
              若想其订单利润率高于：{{ item.profitRatio || '-' }}，建议售价至少为：￥{{ item.sellPrice }}</el-row>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>

</template>

<script>
export default {
  name: "Calculator",
  components: {},
  data() {
    return {
      types: [
        { index: 0, label: '有13%专票采购且有消', name: '供应商13%专票采购且有消费税', desc: '条件：供货商开具13%的增值税专票,应消费税的如金银珠宝及钻石产品；平台的扣点开具6%的增值税专票；利润率皆指业务(售价)利润率，费用仅含直接销售费用（证书费/入网费/快递费/物料包装费/配件费/赠品等）。' },
        { index: 1, label: '有13%专票采购无消或B2B', name: '供应商13%专票采购无消费税或B2B', desc: '条件：供货商开具13%的增值税专票,无消费税的如吹风机、滴露、投资金(豆)、B2B单等产品；平台的扣点开具6%的增值税专票；利润率皆指业务(售价)利润率，费用仅含直接销售费用（证书费/入网费/快递费/物料包装费/配件费/赠品等）。' },
        { index: 2, label: '有9%专票采购且无消', name: '供应商9%专票采购且无消费税', desc: '条件：供货商开具9%的增值税专票,无消费税的如农产品、食用植物油等产品；平台扣点开具6%的增值税专票；利润率皆指业务(售价)利润率，费用仅含直接销售费用（证书费/入网费/快递费/物料包装费/配件费/赠品等）。' },
        { index: 3, label: '无发票采购且有消', name: '供应商无发票采购且有消费税', desc: '条件：供货商无发票采购,应消费税的如金银珠宝及钻石产品；平台扣点开具6%的增值税专票；利润率皆指业务(售价)利润率，费用仅含直接销售费用（证书费/入网费/快递费/物料包装费/配件费/赠品等）。' },
        { index: 4, label: '无发票采购且无消', name: '供应商无发票采购且无消费税', desc: '条件：供货商无发票采购,无消费税的如金银珠宝产品的B2B；平台扣点开具6%的增值税专票；利润率皆指业务(售价)利润率，费用仅含直接销售费用（证书费/入网费/快递费/物料包装费/配件费/赠品等）。' },
      ],
      form: {},
      rules: {
        productPrice: [
          { required: true, message: '请输入对应金额', trigger: 'blur' },
          { pattern: /^(([1-9]{1}\d{0,9})|(0{1}))(\.\d{1,2})?$/, message: "请输入合法的金额数字，最多两位小数", trigger: "blur" }
        ],
        otherPrice: [
          { required: true, message: '请输入对应金额', trigger: 'blur' },
          { pattern: /^(([1-9]{1}\d{0,9})|(0{1}))(\.\d{1,2})?$/, message: "请输入合法的金额数字，最多两位小数", trigger: "blur" }
        ],
        sellPrice: [
          { required: true, message: '请输入对应金额', trigger: 'blur' },
          { pattern: /^(([1-9]{1}\d{0,9})|(0{1}))(\.\d{1,2})?$/, message: "请输入合法的金额数字，最多两位小数", trigger: "blur" }
        ],
        chargeRatio: [
          { required: true, message: '请输入对应扣点比例（百分比）', trigger: 'blur' },
          { pattern: /^(100|([1-9][0-9]?)|(0|[1-9][0-9]?)(\.[\d]{1,2}))$/, message: "请输入合法的百分比数值，最多两位小数", trigger: "blur" }
        ],
        taxRatio: [
          { required: true, message: '请输入对应扣点比例（百分比）', trigger: 'blur' },
          { pattern: /^(100|([1-9][0-9]?)|(0|[1-9][0-9]?)(\.[\d]{1,2}))$/, message: "请输入合法的百分比数值，最多两位小数", trigger: "blur" }
        ]
      },
      ladders: [],
    };
  },
  methods: {
    inputChange: function () {
      this.ladders = [];

      //console.log(JSON.stringify(this.form))
      let CValue = parseFloat(this.form.productPrice || '0.00'); //供货价
      let DValue = parseFloat(this.form.sellPrice || '0.00'); //售价
      let EValue = parseFloat(this.form.chargeRatio || '0.00') / 100.00; //平台扣点
      let FValue = parseFloat(this.form.otherPrice || '0.00'); //证书等直销费用

      let GValue = parseFloat(this.form.taxRatio || '0.00') / 100.00; //开票扣点

      if (CValue < 0 || DValue < 0 || EValue < 0) return;

      if (this.form.index == 0) {
        //增值税
        let HValue = this.form.addTaxPrice = (DValue - CValue) / 1.13 * 0.13 - DValue * EValue / 1.06 * 0.06;
        //消费税
        let IValue = this.form.saleTaxPrice = DValue / 1.13 * 0.05;
        //城建税及附加
        let JValue = this.form.cityTaxPrice = (HValue + IValue) * 0.12;
        //利润额
        let KValue = this.form.profitPrice = (DValue - CValue) / 1.13 - DValue * EValue / 1.06 - FValue - IValue - JValue;
        //利润率
        let LValue = this.form.profitRatio = DValue > 0 ? (KValue / DValue * 100.00).toFixed(2) : '0.00';
      }
      else if (this.form.index == 1) {
        //增值税
        let HValue = this.form.addTaxPrice = (DValue - CValue) / 1.13 * 0.13 - DValue * EValue / 1.06 * 0.06;
        //城建税及附加
        let JValue = this.form.cityTaxPrice = HValue * 0.12;
        //利润额
        let KValue = this.form.profitPrice = (DValue - CValue) / 1.13 - DValue * EValue / 1.06 - FValue - JValue;
        //利润率
        let LValue = this.form.profitRatio = DValue > 0 ? (KValue / DValue * 100.00).toFixed(2) : '0.00';
      }
      else if (this.form.index == 2) {
        //增值税
        let HValue = this.form.addTaxPrice = (DValue - CValue) / 1.09 * 0.09 - DValue * EValue / 1.06 * 0.06;
        //城建税及附加
        let JValue = this.form.cityTaxPrice = HValue * 0.12;
        //利润额
        let KValue = this.form.profitPrice = (DValue - CValue) / 1.09 - DValue * EValue / 1.06 - FValue - JValue;
        //利润率
        let LValue = this.form.profitRatio = DValue > 0 ? (KValue / DValue * 100.00).toFixed(2) : '0.00';
      }
      else if (this.form.index == 3) {
        if (GValue < 0) return;

        //增值税
        let HValue = this.form.addTaxPrice = (DValue / 1.13 - (DValue / 1.13 - 0.015 * DValue / 1.13 / 0.13)) * 0.13 - (DValue * EValue / 1.06 * 0.06);
        //消费税
        let IValue = this.form.saleTaxPrice = DValue / 1.13 * 0.05;
        //城建税及附加
        let JValue = this.form.cityTaxPrice = (HValue + IValue) * 0.12;
        //利润额
        let KValue = this.form.profitPrice = DValue / 1.13 - CValue - FValue - DValue * EValue / 1.06 - IValue - JValue + (DValue / 1.13 - 0.015 * DValue / 1.13 / 0.13) * (0.13 - 1.13 * GValue);
        //利润率
        let LValue = this.form.profitRatio = DValue > 0 ? (KValue / DValue * 100.00).toFixed(2) : '0.00';
      }
      else if (this.form.index == 4) {
        if (GValue < 0) return;

        //增值税
        let HValue = this.form.addTaxPrice = (DValue / 1.13 - (DValue / 1.13 - 0.015 * DValue / 1.13 / 0.13)) * 0.13 - (DValue * EValue / 1.06 * 0.06);
        //城建税及附加
        let JValue = this.form.cityTaxPrice = HValue * 0.12;
        //利润额
        let KValue = this.form.profitPrice = DValue / 1.13 - CValue - FValue - DValue * EValue / 1.06 - JValue + (DValue / 1.13 - 0.015 * DValue / 1.13 / 0.13) * (0.13 - 1.13 * GValue);
        //利润率
        let LValue = this.form.profitRatio = DValue > 0 ? (KValue / DValue * 100.00).toFixed(2) : '0.00';
      }

      for (let i = 10; i <= 70; i += 5)
        this.ladders.push({ sellPrice: this.profitRatioToSellPrice(i), profitRatio: i + '%' });

      this.inverseChange();
    },
    otherChange: function () {
      this.form.otherPrice = (parseFloat(this.form.otherCertPrice || '0.00')
        + parseFloat(this.form.otherBrandPrice || '0.00')
        + parseFloat(this.form.otherPostPrice || '0.00')
        + parseFloat(this.form.otherFittPrice || '0.00')
        + parseFloat(this.form.otherGiftPrice || '0.00')
        + parseFloat(this.form.otherExtraPrice || '0.00')).toFixed(2);
      this.inputChange();
    },
    selectChange: function () {
      let that = this;
      let selectors = this.types.filter(res => res.index === that.form.index);
      if (selectors.length == 0) return;

      console.log(selectors)

      let selector = selectors[0];
      this.form.index = selector.index;
      this.form.desc = selector.desc;
      console.log(this.form.index)

      this.inputChange();
    },
    inverseChange: function () {
      let OValue = parseFloat(this.form.fix1ProfitRatio || '0.00');
      this.form.fix1SellPrice = this.profitRatioToSellPrice(OValue);

      let QValue = parseFloat(this.form.fix2SellPrice || '0.00');
      this.form.fix2ProfitRatio = this.sellPriceToProfitRatio(QValue);
    },
    //输入利润率输出销售价
    profitRatioToSellPrice: function (ProfitRatio) {
      let CValue = parseFloat(this.form.productPrice || '0.00'); //供货价
      let DValue = parseFloat(this.form.sellPrice || '0.00'); //售价
      let EValue = parseFloat(this.form.chargeRatio || '0.00') / 100.00; //平台扣点
      let FValue = parseFloat(this.form.otherPrice || '0.00'); //证书等直销费用

      let OValue = parseFloat(ProfitRatio || '0.00') / 100.00;
      let NValue = 0.00;
      if (OValue <= 0) return NValue;

      let GValue = parseFloat(this.form.taxRatio || '0.00') / 100.00; //开票扣点

      if (this.form.index == 0) {
        NValue = (0.9844 * CValue + 1.13 * FValue) / (0.9284 - 1.13 * OValue - 1.058363 * EValue);
      }
      else if (this.form.index == 1) {
        NValue = (0.9844 * CValue + 1.13 * FValue) / (0.9844 - 1.13 * OValue - 1.058363 * EValue);
      }
      else if (this.form.index == 2) {
        NValue = (0.9892 * CValue + 1.09 * FValue) / (0.9892 - 1.02089812 * EValue - 1.09 * OValue);
      }
      else if (this.form.index == 3) {
        NValue = (CValue + FValue) / (0.93557522 - 0.93660377 * EValue - 0.88461538 * GValue - OValue);
      }
      else if (this.form.index == 4) {
        NValue = (CValue + FValue) / (0.98513274 - 0.93660377 * EValue - 0.88461538 * GValue - OValue)
        //console.log(NValue + '=(' + CValue + ' + ' + FValue + ') / (0.98513274 - 0.93660377 * ' + EValue + ' - 0.88461538 * ' + GValue + ' - ' + OValue + ')');
      }

      return NValue.toFixed(2);
    },
    //输入销售价输出利润率
    sellPriceToProfitRatio: function (SellPrice) {
      let CValue = parseFloat(this.form.productPrice || '0.00'); //供货价
      let DValue = parseFloat(this.form.sellPrice || '0.00'); //售价
      let EValue = parseFloat(this.form.chargeRatio || '0.00') / 100.00; //平台扣点
      let FValue = parseFloat(this.form.otherPrice || '0.00'); //证书等直销费用

      let QValue = parseFloat(SellPrice || '0.00');  //售价
      let PValue = 0.00;
      if (QValue <= 0) return PValue;

      let GValue = parseFloat(this.form.taxRatio || '0.00') / 100.00; //开票扣点

      if (this.form.index == 0) {
        PValue = 0.9284 / 1.13 - 0.9844 * CValue / (QValue * 1.13) - 1.058363 * EValue / 1.13 - FValue / QValue;
      }
      else if (this.form.index == 1) {
        PValue = 0.9844 / 1.13 - 0.9844 * CValue / (QValue * 1.13) - 1.058363 * EValue / 1.13 - FValue / QValue;
      }
      else if (this.form.index == 2) {
        PValue = 0.9892 / 1.09 - 0.9892 * CValue / (QValue * 1.09) - 1.02089812 * EValue / 1.09 - FValue / QValue;
      }
      else if (this.form.index == 3) {
        PValue = 0.93557522 - 0.93660377 * EValue - 0.88461538 * GValue - (CValue + FValue) / DValue;
      }
      else if (this.form.index == 4) {
        PValue = 0.98513274 - 0.93660377 * EValue - 0.88461538 * GValue - (CValue + FValue) / DValue;
      }

      return (PValue * 100.00).toFixed(2);
    }
  },
  created: function () {
    this.form.index = this.types[0].index;
    this.selectChange();
  },
  mounted() {
  },
};
</script>